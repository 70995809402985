import React from 'react'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link } from 'gatsby'
import Image from '../../generic/Image'
import { trackEvent } from '../../../utils/ArtboxoneTracking'
import { CMSLinkWithCounter } from './Column'

export interface NaviLinkOptions {
  link: CMSLinkWithCounter
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  position: string
}

const NaviLink = (options: NaviLinkOptions) => {
  const link = options.link
  const position = options.position

  const onClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    const targetUrl = ev.currentTarget.href.replace('https://www.', '')
    const label = position + ',' + targetUrl

    trackEvent({
      category: 'navi',
      action: 'click-link',
      label: label,
    })

    options.setExpanded(false)
  }

  let elem = undefined

  if (link.grafik === null) {
    const targetLink = link.target.value

    if (elem === undefined) {
      elem = (
        <NavDropdown.Item
          as={Link}
          key={link.id}
          onClick={onClick}
          to={targetLink}
        >
          {link.name.value}
        </NavDropdown.Item>
      )
    }
  } else {
    elem = (
      <NavDropdown.Item
        as={Link}
        key={link.id}
        onClick={onClick}
        to={link.target.value}
      >
        <Image alt={link.name.value} url={link.grafik.value.publicURL} />
      </NavDropdown.Item>
    )
  }

  let className = ''
  const desktop = link.desktop.value == true
  const mobile = link.mobile.value == true

  if (desktop && !mobile) {
    className = 'd-none d-sm-block'
  } else if (!desktop && mobile) {
    className = 'd-block d-sm-none'
  } else if (!desktop && !mobile) {
    className = 'd-none'
  }

  return <div className={className}>{elem}</div>
}

export default NaviLink
