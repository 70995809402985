import React from 'react'
import EditIcon from '@mui/icons-material/Edit'

export interface EditProps {
  type: string
  cockpitId: string
  description?: string
}

export default function Edit(props: EditProps) {
  const baseUrl = 'https://cms.artbox.one/collections/entry/'

  if (process.env.SHOW_CMS_LINKS == '1' && props.cockpitId !== '') {
    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <a
              href={baseUrl + props.type + '/' + props.cockpitId}
              rel="noreferrer" target="_blank"
            >
              <EditIcon />
              {props.description}
            </a>
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}
