import { isBrowser, daysIntoYear } from './utils'
import { isFavorite, addFavorite, getFavorites } from '../lib/favorites_hook'
import { addClean } from '../lib/clean_hook'
import { removeDirty } from '../lib/dirty_hook'
import {
  getCart,
  setVoucherCode,
  setMailaddress,
  setDeliveryAddress,
  setInvoiceAddress,
} from '../lib/cart_hook'
import { getCartItems, isInCart, addCartItem } from '../lib/cartitems_hook'
import { CartItem } from '../types'
import {
  getOrders,
  addOrder,
  isInOrders,
  addOrderObject,
} from '../lib/orders_hook'
import { localStorage_setItem } from './LocalStorageHelper'

export const clearLocalStorage = () => {
  if (isBrowser()) {
    const prefix = '' + process.env.STORAGE_PREFIX

    for (const key in localStorage) {
      if (key.indexOf(prefix) === 0) {
        localStorage.removeItem(key)
      }
    }
  }
}

export const setExtVersion = (version: string) => {
  localStorage_setItem(
    process.env.STORAGE_PREFIX + '_eversion',
    JSON.stringify({ version: version }),
  )
}

export const getExtVersion = () => {
  if (isBrowser()) {
    const cartLocalStorage = localStorage.getItem(
      process.env.STORAGE_PREFIX + '_eversion',
    )

    if (typeof cartLocalStorage == 'string') {
      const result = JSON.parse(cartLocalStorage)

      return result
    } else {
      return { version: 'fallback' }
    }
  } else {
    return { version: 'fallback' }
  }
}

export const getLocalStorageKeys = (type: string) => {
  const result: any = []
  const prefix = '' + process.env.STORAGE_PREFIX
  const doNotDelete: string[] = ['dirty']
  const doDelete: string[] = []

  if (type != 'orders') {
    doNotDelete.push('orders')
  } else {
    doDelete.push('orders')
  }

  if (type != 'favorites') {
    doNotDelete.push('favorites')
  } else {
    doDelete.push('favorites')
  }

  if (type != 'cart') {
    doNotDelete.push('cart')
    doNotDelete.push('cartitems')
  } else {
    doDelete.push('cart')
    doDelete.push('cartitems')
  }

  for (const key in localStorage) {
    if (key.indexOf(prefix + '_') === 0) {
      let identifier = key.replace(prefix + '_', '')
      const j = identifier.indexOf('_')

      if (j >= 0) {
        identifier = identifier.substring(0, j)
      }

      result.push({
        key: key,
        identifier: identifier,
      })
    }
  }

  return result.filter((node: any) => {
    if (doDelete.length > 0) {
      return doDelete.indexOf(node.identifier) >= 0
    } else {
      return doNotDelete.indexOf(node.identifier) == -1
    }
  })
}

export const clearLocalStorageExtVersion = () => {
  if (isBrowser()) {
    const prefix = '' + process.env.STORAGE_PREFIX

    for (const key in localStorage) {
      if (key.indexOf(prefix + '_materials_') === 0) {
        localStorage.removeItem(key)
      } else if (key.indexOf(prefix + '_sale_') === 0) {
        localStorage.removeItem(key)
      }
    }
  }
}

export const clearLocalStorageVersion = () => {
  if (isBrowser()) {
    const prefix = '' + process.env.STORAGE_PREFIX
    const contains = '_v'
    const contains2 = '_day'
    const suffix = '' + process.env.STORAGE_SUFFIX
    const suffix2 = '' + daysIntoYear()

    for (const key in localStorage) {
      if (
        key.indexOf(prefix) === 0 &&
        key.indexOf(contains) > 0 &&
        key.indexOf(suffix) === -1
      ) {
        console.log('Remove localestorage key: ' + key)
        localStorage.removeItem(key)
      }

      if (
        key.indexOf(prefix) === 0 &&
        key.indexOf(contains2) > 0 &&
        key.indexOf(suffix2) === -1
      ) {
        console.log('Remove localestorage key: ' + key)
        localStorage.removeItem(key)
      }
    }
  }
}
