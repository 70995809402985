import React from 'react'
// import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
// import { useStaticQuery, graphql } from 'gatsby'

export interface SeoOptions {
  title?: string
  description?: string
  lang?: string
  meta?: any[]
}

function SEO(options: SeoOptions) {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //           author
  //         }
  //       }
  //     }
  //   `,
  // )

  const elements = []
  let meta = []

  if (options.title) {
    elements.push(<title key="seoTitle">{options.title}</title>)
    meta.push({ name: 'og:title', content: options.title })
    meta.push({ name: 'og:type', content: 'website' })

    meta.push({ name: 'twitter:card', content: 'summary' })
    meta.push({ name: 'twitter:creator', content: 'artboxONE' })
    meta.push({ name: 'twitter:title', content: options.title })
  }

  if (options.description) {
    meta.push({ name: 'description', content: options.description })
    meta.push({ name: 'og:description', content: options.description })
    meta.push({ name: 'twitter:description', content: options.description })
  }

  if (options.meta) {
    meta = meta.concat(options.meta)
  }

  meta.map((meta: any) => {
    elements.push(
      <meta content={meta.content} key={meta + meta.name} name={meta.name} />,
    )
  })

  let result = <Helmet key="seoHelmet">{elements}</Helmet>

  if (options.lang) {
    const lang = options.lang
    result = (
      <Helmet htmlAttributes={{ lang }} key="seoHelmet">
        {elements}
      </Helmet>
    )
  }

  return result
}

export default SEO
