import { mapSlug2InternalKey } from './UrlUtils'
import { ProductMaterial } from '../types'

export const getMaterialIidFromVariant = (variant: string) => {
  return parseInt(mapSlug2InternalKey(variant))
}

export const getProductMaterialFromIid = (
  materialsData: ProductMaterial[],
  materialIId?: number,
) =>
  materialsData.find(
    (materialData: ProductMaterial) => materialData.iid == materialIId,
  )
