import { isBrowser } from './utils'
import { env } from '../../environment'
import { OrderProps, ProductData, ProductViewTrackingData } from '../types'
import { ParsedSku } from '../../public/static/3a9aaa0ce94db3fcda152e73a28c27d5/types'

export interface EventData {
  category: string
  action: string
  label: string
  value?: string
}

export const trackEvent = (event: EventData) => {
  if (isBrowser()) {
    const sendEvent: any = {
      event: 'artboxone.eventtracking',
      'artboxone.event.category': event.category,
      'artboxone.event.action': event.action,
      'artboxone.event.label': event.label,
    }

    const props = {
      category: event.category,
      action: event.action,
      label: event.label,
      interactive: 'true',
    }

    if (typeof event.value === 'string') {
      sendEvent['artboxone.event.value'] = event.value
      props['value'] = event.value
    } else {
      sendEvent['artboxone.event.value'] = ''
    }

    window.dataLayer?.push(sendEvent)
    window.plausible(event.category, { props: props })
  }
  // ReactGA.event(event)
}

export const trackNonInteractionEvent = (event: EventData) => {
  if (isBrowser()) {
    const props = {
      category: event.category,
      action: event.action,
      label: event.label,
      interactive: 'false',
    }
    window.plausible(event.category, { props: props })

    window.dataLayer?.push({
      event: 'artboxone.eventnoninteractiontracking',
      'artboxone.event.category': event.category,
      'artboxone.event.action': event.action,
      'artboxone.event.label': event.label,
      'artboxone.event.value': '',
    })
  }
  // ReactGA.event(event)
}

const pushPageviewToJts = (path: string) => {
  const language = env.getLanguage()
  const pageType = window.ARTBOXONE_ENV?.pageType || 'home'

  window._jts.push({
    track: 'pageview',
    page: {
      category: pageType,
      entity: `artboxone.${language}`,
      language: language,
      name: path,
      type: 'pageview',
    },
  })
}

const pushTrackSubmitToJts = () => {
  window._jts.push({
    track: 'submit',
  })
}

export const trackPageView = (path: string) => {
  if (isBrowser()) {
    window.dataLayer?.push({
      event: 'artboxone.pageview',
      'artboxone.path': path,
    })
  }

  if (window._jts) {
    pushPageviewToJts(path)
    pushTrackSubmitToJts()
  }
  // ReactGA.pageview(path)
}

export const trackProductOrder = (cartItem: any) => {
  const { motiveId, materialIId, name, count, amountGross, sku } = cartItem
  window._jts.push({
    track: 'product',
    type: 'order',
    id: materialIId,
    artbox_motiv_id: motiveId,
    artbox_sku: sku,
    name: name,
    quantity: count,
    brutto: amountGross,
    group: sku,
  })
}

export const submitCartProductsOrderTracking = (order: OrderProps) => {
  if (isBrowser() && window._jts) {
    order.items.map((cartItem: any) => {
      trackProductOrder(cartItem)
    })

    const {
      emailHash,
      email,
      orderId,
      orderAmountGross,
      orderAmountGrossNet,
      orderShippingGross,
    } = order

    window._jts.push({
      orderid: orderId,
      track: 'order',
      hashed_email: emailHash,
      order_email: email,
      brutto: orderAmountGross,
      netto: orderAmountGrossNet,
      shipping: orderShippingGross,
    })

    window._jts.push({
      track: 'submit',
    })
  }
}

export const trackProductView = ({
  materialId,
  productName,
  motiveId,
  productSku,
  price,
}: ProductViewTrackingData) => {
  if (isBrowser() && window._jts) {
    window._jts.push({
      track: 'product',
      type: 'productview',
      id: materialId,
      name: productName,
      artbox_motiv_id: motiveId,
      artbox_sku: productSku,
      brutto: price,
      group: productSku,
    })

    window._jts.push({
      track: 'productview',
    })

    window._jts.push({
      track: 'submit',
    })
  }
}

export const initializeTracking = () => {
  // Done in GTM
  // ReactGA.initialize('UA-55985934-7', {
  //   debug: false, //process.env.NODE_ENV !== 'production',
  // })
  // ReactGA.set({ anonymizeIp: true })
}
