const isConnectedMaterial = (materialData, searchMaterial) => {
  let searchMaterialGroup = null

  if (
    [
      'poster',
      'cups',
      'poster_plastic',
      'poster_wooden_frame',
      'poster_metal_frame',
      'cases',
      'giftvoucher',
      'puzzle',
      'tshirt',
      'sweatshirt',
      'hoodie',
      'advent',
    ].indexOf(searchMaterial) !== -1
  ) {
    searchMaterialGroup = searchMaterial
  }

  let result = false

  if (searchMaterialGroup !== null) {
    result = materialData.materialgroup === searchMaterialGroup
  } else {
    result = materialData.material === searchMaterial
  }

  // console.log(
  //   'Connected? ' +
  //     searchMaterial +
  //     ' -> ' +
  //     searchMaterialGroup +
  //     ' == ' +
  //     materialData.materialgroup +
  //     ' (' +
  //     materialData.name +
  //     ' ' +
  //     result +
  //     ')',
  // )
  return result
}

const isConnectedMaterialBundle = (materialData, searchMaterial) => {
  if (searchMaterial === 'cup') {
    searchMaterial = 'cups'
  }

  if (searchMaterial === 'case') {
    searchMaterial = 'cases'
  }

  if (searchMaterial === 'poster_frame') {
    searchMaterial = 'poster_metal_frame'
  }

  return materialData.materialgroup === searchMaterial
}

const materialGroupMappings = {
  poster_wooden_frame: 'poster_frame',
  poster_metal_frame: 'poster_frame',
  cups: 'cup',
  cases: 'case',
}

const mapMaterialToApiGroup = material => {
  if (typeof materialGroupMappings[material] === 'string') {
    return materialGroupMappings[material]
  } else {
    return material
  }
}

module.exports = {
  isConnectedMaterial,
  isConnectedMaterialBundle,
  mapMaterialToApiGroup,
}
