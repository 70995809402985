import React, { ReactNode } from 'react'
import { SearchProvider } from './../context/SearchContext'
import { PageInformationProvider } from '../context/PageInformationContext'
import { LinearProgress } from '@mui/material'

interface RootProviderProps {
  element: ReactNode
}

export const ClientRootProvider: () => React.FC<RootProviderProps> = () => {
  const Component: React.FC<RootProviderProps> = ({ element }) => {
    return (
      <React.Suspense fallback={<LinearProgress />}>
        <SearchProvider>
          <PageInformationProvider>
            {element}
          </PageInformationProvider>
        </SearchProvider>
      </React.Suspense>
    )
  }

  Component.displayName = 'ClientRootProvider'

  return Component
}
