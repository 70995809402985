import { useState, useEffect } from 'react'
import fetchVersion from './version_api'

export const useVersionData = (options: any) => {
  const [version, setVersion] = useState(undefined)

  useEffect(() => {
    fetchVersion(options).then((response: any) => {
      setVersion(response.data)
    })
  }, [])

  return version
}
