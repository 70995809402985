const {
  LOCALE,
  LANGUAGE,
  COUNTRY,
  BASE_URL,
  SEO,
  PIXUM_URL,
  ACTIVATE_KAMELEOON,
  ACTIVATE_PLAUSIBLE,
  SERVICE_HOST,
  PIXUM_PSI,
} = require('./defaults')

const ARTBOXONE_ENV = {
  SERVICE_HOST,
  PIXUM_PSI,
  LOCALE,
  LANGUAGE,
  COUNTRY,
  BASE_URL,
  SEO,
  PIXUM_URL,
  ACTIVATE_KAMELEOON,
  ACTIVATE_PLAUSIBLE,
}

const env = {
  getBaseUrl: () => {
    return ARTBOXONE_ENV.BASE_URL
  },
  getServiceHost: () => {
    return ARTBOXONE_ENV.SERVICE_HOST
  },
  getLanguage: () => {
    return ARTBOXONE_ENV.LANGUAGE
  },
  getLocale: () => {
    return ARTBOXONE_ENV.LOCALE
  },
  getCountry: () => {
    return ARTBOXONE_ENV.COUNTRY
  },
  getSeo: () => {
    return ARTBOXONE_ENV.SEO
  },
  getPixumDomain: () => {
    return ARTBOXONE_ENV.PIXUM_URL
  },
  getPixumPSI: () => {
    return ARTBOXONE_ENV.PIXUM_PSI
  },
  getCmsLanguage: () => {
    return ARTBOXONE_ENV.LANGUAGE.toLocaleLowerCase()
  },
}

module.exports = { env, ARTBOXONE_ENV }
