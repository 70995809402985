import { isBrowser , daysIntoYear } from './utils'
import fetchError from '../lib/global/error_api'
import LRU from 'lru-cache'
import { getHash } from './Crypto'

const cache = new LRU({ max: 50 })

export const localStorage_getItem = (key: string) => {
  if (process.env.ENABLE_LOCALSTORAGE == '1' && isBrowser()) {
    return localStorage.getItem(key)
  } else {
    return null
  }
}

export const localStorage_setItem = (key: string, data: any) => {
  if (process.env.ENABLE_LOCALSTORAGE == '1' && isBrowser()) {
    try {
      localStorage.setItem(key, data)
      return true
    } catch (QuotaExceededError) {
      localStorage_logError(key, data.length)
    }
  }

  return false
}

export const localStorage_logError = (key: string, length: number) => {
  let message =
    '[QuotaExceededError] While writing "' +
    key +
    '" with additional ' +
    Math.round((length / 1024) * 100) / 100 +
    'kb.'

  const signature = getHash(key)

  const cacheResult = cache.get(signature)

  if (typeof cacheResult === 'undefined') {
    let storageSize = 0

    for (var key in localStorage) {
      const value = localStorage.getItem(key)

      if (value !== null) {
        storageSize += key.length + value.length

        message +=
          '\n' + key + ': ' + Math.round((value.length / 1024) * 100) / 100
      }
    }

    message += '\nSize: ' + Math.round((storageSize / 1024) * 100) / 100 + ' kb'

    console.warn(message)

    fetchError({
      data: message,
    })

    cache.set(signature, 1)
  }
}

export const localStorage_setItemWithDaySuffix = (key: string, data: any) => {
  return localStorage_setItem(key + '_day' + daysIntoYear(), data)
}

export const localStorage_getItemWithDaySuffix = (key: string) => {
  return localStorage_getItem(key + '_day' + daysIntoYear())
}
