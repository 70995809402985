import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export type Language = 'en' | 'de'

const I18N_FALLBACK_LANGUAGE = {
  de: 'de',
  en: 'en',
}

enum I18N_NAMESPACE {
  TRANSLATION = 'translation',
}

const initI18n = (lng: Language, backend: any, options = {}) => {
  const i18nInstance = i18n.createInstance().use(initReactI18next)

  i18nInstance.use(backend)

  i18nInstance.init({
    lng,
    fallbackLng: I18N_FALLBACK_LANGUAGE[lng],
    ns: [I18N_NAMESPACE.TRANSLATION],
    debug: false, //process.env.NODE_ENV !== 'production',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ...options,
  })

  return i18nInstance
}

export default initI18n
