import React from 'react'

export interface EvilDivProps {
  content: any
}

export default function EvilDiv(props: EvilDivProps) {
  const content = {
    __html: props.content,
  }

  return <div dangerouslySetInnerHTML={content}></div>
}
