import { CartItem } from '../types'
import { mapMaterialToApiGroup } from './Mapping'

export const getMotiveIdFromSku = (sku?: string) => {
  const matches = sku && sku.match(/([0-9]+)-(.+)/)

  if (matches != null) {
    return parseInt(matches[1])
  }

  return undefined
}

export const getMotiveFromCartItem = (cartItem: CartItem, products?: any) => {
  const { motiveId, ratio } = cartItem
  const materialApiGroup = mapMaterialToApiGroup(cartItem.material)

  if (products[motiveId] && products[motiveId][materialApiGroup]) {
    return products[motiveId][materialApiGroup][ratio]
  }

  return undefined
}
