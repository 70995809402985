import { localStorage_setItem } from '../utils/LocalStorageHelper'
import { isBrowser } from '../utils/utils'
import { addDirty } from './dirty_hook'

export const _getLocalStorageId = () => {
  return process.env.STORAGE_PREFIX + '_favorites'
}

export const _getLocalStorageIdOld = () => {
  return process.env.STORAGE_PREFIX + '_favorites_' + process.env.STORAGE_SUFFIX
}

export const _setFavorites = (favorites: number[]) => {
  addDirty('favorites')
  localStorage_setItem(
    _getLocalStorageId(),
    JSON.stringify(
      favorites.filter((motive: any) => {
        const remove =
          motive.id === null || isNaN(motive.id) || isNaN(motive.ratio)
        return !remove
      }),
    ),
  )
}

export const addFavorite = (
  motiveId: number,
  material: string,
  ratio: number,
) => {
  const favorites = getFavorites()
  const add = { id: motiveId, material: material, ratio: ratio }

  favorites.push(add)
  _setFavorites(favorites)

  return add
}

export const removeFavorite = (
  motiveId: number,
  material: string,
  ratio: number,
) => {
  const favorites = getFavorites()
  _setFavorites(
    favorites.filter((motive: any) => {
      const remove =
        motive.id === motiveId &&
        motive.material === material &&
        motive.ratio === ratio
      return !remove
    }),
  )
}

export const isFavorite = (
  motiveId: number,
  material: string,
  ratio: number,
) => {
  return (
    getFavorites().filter((motive: any) => {
      return (
        motive.id == motiveId &&
        motive.material == material &&
        motive.ratio === ratio
      )
    }).length > 0
  )
}

export const getFavoritesCount = () => {
  const favoritesLocalStorage = localStorage.getItem(_getLocalStorageId())

  if (typeof favoritesLocalStorage == 'string') {
    return JSON.parse(favoritesLocalStorage).length
  } else {
    return 0
  }
}

export const getFavorites = () => {
  if (!isBrowser()) {
    return []
  }

  let favoritesLocalStorage = localStorage.getItem(_getLocalStorageId())

  if (typeof favoritesLocalStorage == 'string') {
    return JSON.parse(favoritesLocalStorage)
  } else {
    favoritesLocalStorage = localStorage.getItem(_getLocalStorageIdOld())

    if (typeof favoritesLocalStorage == 'string') {
      const result = JSON.parse(favoritesLocalStorage)
      _setFavorites(result)
      return result
    } else {
      return []
    }
  }
}
