import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export interface ImageProps {
  id?: string
  filename?: string
  url?: string
  alt?: string
}

const Image = (options: ImageProps) => {
  // placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
  // fluid(maxWidth: 124, maxHeight: 124, quality: 80) {

  // "gatsby-astronaut.png"
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            id
            relativePath
            publicURL

            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)

  let checkType: undefined | string = undefined

  if (typeof options.id !== 'undefined') {
    checkType = 'id'
  } else if (typeof options.filename !== 'undefined') {
    checkType = 'filename'
  } else if (typeof options.url !== 'undefined') {
    checkType = 'url'
  } else {
    return <></>
  }

  const image = data.images.edges.find((n: any) => {
    return (
      (checkType === 'url' && n.node.publicURL === options.url) ||
      (checkType === 'id' && n.node.id === options.id) ||
      n.node.relativePath.includes(options.filename)
    )
  })

  if (!image || !image.node || !image.node.childImageSharp) {
    return <></>
  } else {
    const i = getImage(image.node.childImageSharp)

    if (typeof i == 'undefined') {
      return <></>
    } else {
      let alt = ''

      if (typeof options.alt != 'undefined') {
        alt = options.alt
      }

      return <GatsbyImage alt={alt} image={i} />
    }
  }
}

export default Image
