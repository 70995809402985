import { isBrowser } from '../utils/utils'
import { Cart, Address } from '../types'
import { addDirty } from './dirty_hook'
import { getCartItems } from './cartitems_hook'
import { localStorage_setItem } from '../utils/LocalStorageHelper'
import { getHash } from '../utils/Crypto'

export const _getLocalStorageIdCart = () => {
  return process.env.STORAGE_PREFIX + '_cart'
}

export const setSeenPrices = (
  materialGross: number,
  shipping: number,
  bonus: number,
) => {
  localStorage_setItem(
    process.env.STORAGE_PREFIX + '_prices',
    JSON.stringify({
      materialGross: materialGross,
      shipping: shipping,
      bonus: Math.round((bonus + Number.EPSILON) * 100) / 100,
    }),
  )
}

export const getSeenPrices = () => {
  if (isBrowser()) {
    const cartLocalStorage = localStorage.getItem(
      process.env.STORAGE_PREFIX + '_prices',
    )

    if (typeof cartLocalStorage == 'string') {
      const result = JSON.parse(cartLocalStorage)

      return result
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

export const _setCart = (cart: Cart) => {
  addDirty('cart')
  return localStorage_setItem(_getLocalStorageIdCart(), JSON.stringify(cart))
}

export const setInvoiceAddress = (address?: Address) => {
  const cart = getCart()

  cart.invoice_address = address

  return _setCart(cart)
}

export const setDeliveryAddress = (address: Address) => {
  const cart = getCart()

  cart.delivery_address = address

  return _setCart(cart)
}

export const setVoucherCode = (voucherCode: string) => {
  const cart = getCart()

  cart.voucher_code = voucherCode

  return _setCart(cart)
}

export const setUuid = (uuid: string) => {
  const cart = getCart()

  cart.uuid = uuid

  return _setCart(cart)
}

export const setCartPayment = (payment: string) => {
  const cart = getCart()

  cart.payment = payment

  return _setCart(cart)
}

export const setCartPaymentData = (
  paymentName: string,
  paymentNumber: string,
) => {
  return localStorage_setItem(
    process.env.STORAGE_PREFIX + '_payment',
    JSON.stringify({
      paymentName: paymentName,
      paymentNumber: paymentNumber,
    }),
  )
}

export const getCartPaymentData = () => {
  if (isBrowser()) {
    const r = localStorage.getItem(process.env.STORAGE_PREFIX + '_payment')

    if (typeof r == 'string') {
      const result = JSON.parse(r)

      return result
    } else {
      return {
        paymentName: '',
        paymentNumber: '',
      }
    }
  }

  return undefined
}

export const setMailaddress = (mailAddress: string) => {
  const cart = getCart()

  cart.mail_address = mailAddress

  return _setCart(cart)
}

export const getCartHash = () => {
  const cart = getCart()
  const cartItems = getCartItems()

  return getHash({ cart: cart, cartItems: cartItems })
}

export const getCart = () => {
  if (!isBrowser()) {
    return []
  }

  const cartLocalStorage = localStorage.getItem(_getLocalStorageIdCart())
  const voucherCode = ''

  if (typeof cartLocalStorage == 'string') {
    const result = JSON.parse(cartLocalStorage)

    if (result.voucher_code === '') {
      result.voucher_code = voucherCode
    }

    return result
  } else {
    const result: Cart = {
      voucher_code: voucherCode,
      mail_address: '',
      invoice_address: undefined,
      delivery_address: {
        first_name: '',
      },
    }

    return result
  }
}
