import React from 'react'
import NaviLink from './Link'
import {env} from '../../../../environment'
import {
  getPriceCountry,
  getCartCountry,
} from '../../../utils/CountrySelection'
import { CMSLink } from '../../../types/cockpit_cms/link'

export interface NaviColumnOptions {
  title: { value: string }
  fields: { value: CMSLink[] }
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  position: string
}

export type CMSLinkWithCounter = CMSLink & {
  counter: number;
};


const NaviColumn = (options: NaviColumnOptions) => {
  const links: CMSLinkWithCounter[] = []
  const position = options.position

  const isDe =
    env.getCountry() == 'DE' || getPriceCountry(getCartCountry()) == 'de'

  let i = 1

  options.fields &&
    options.fields.value.map((link: CMSLink) => {
      if (!isDe && link.target.value.indexOf('/fashion') >= 0) {
        return <></>
      }

      if (
        link.name.value === '' ||
        link.target.value === '-'
      ) {
        return <></>
      } else {
        const linkWithCounter: CMSLinkWithCounter = { ...link, counter: i}
        links.push(linkWithCounter)
        i++
      }
    })

  if (links.length > 0) {
    return (
      <div className="col-sm-3 padding-fix-mobile">
        <h6 className="dropdown-header">
          {options.title && options.title.value}
        </h6>
        {links.map((link: CMSLinkWithCounter) => (
          <NaviLink
            key={link.id}
            link={link}
            position={position + ',' + link.counter}
            setExpanded={options.setExpanded}
          />
        ))}
      </div>
    )
  } else {
    return <div className="col-sm-3 padding-fix-mobile"></div>
  }
}

export default NaviColumn
