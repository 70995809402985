import React from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { isBrowser } from '../../utils/utils'

export const setEnvironmentVariable = (key: string, value: any) => {
  if (isBrowser()) {
    if (typeof window.ARTBOXONE_ENV == 'undefined') {
      window.ARTBOXONE_ENV = {}
    }

    window.ARTBOXONE_ENV[key] = value
  }
}

export const pushTmEvent = (event: string) => {
  if (isBrowser()) {
    window.dataLayer?.push({ event: event })
  }
}

type DeviceType = 't' | 'm' | 'd'

interface EnvironmentVariables {
  deviceType: DeviceType
}

const EnvironmentVariables = () => {
  const environmentVariables: EnvironmentVariables = {
    deviceType: isTablet ? 't' : isMobile ? 'm' : 'd',
  }

  // let environmentString = JSON.stringify(environmentVariables)

  if (isBrowser()) {
    if (typeof window.ARTBOXONE_ENV == 'undefined') {
      window.ARTBOXONE_ENV = environmentVariables
    } else {
      const keys = Object.keys(environmentVariables)

      for (const key of keys) {
        window.ARTBOXONE_ENV[key] = environmentVariables[key]
      }
    }
  }

  return <></>
}

export default EnvironmentVariables
