import { localStorage_setItem } from '../utils/LocalStorageHelper'
import { isBrowser } from '../utils/utils'

export const _getLocalStorageIdDirty = () => {
  return process.env.STORAGE_PREFIX + '_dirty_' + process.env.STORAGE_SUFFIX
}

export const _setDirty = (dirty: string[]) => {
  localStorage_setItem(_getLocalStorageIdDirty(), JSON.stringify(dirty))
}

export const addDirty = (dirty: string) => {
  const d = getDirty()

  if (d.indexOf(dirty) === -1) {
    d.push(dirty)
    _setDirty(d)
  }
}

export const removeDirty = (dirty: string) => {
  const d = getDirty()
  _setDirty(
    d.filter((dirtyElement: any) => {
      const remove = dirtyElement === dirty
      return !remove
    }),
  )
}

export const isDirty = (dirty?: string) => {
  if (typeof dirty === 'string') {
    return (
      getDirty().filter((dirtyElement: string) => {
        return dirtyElement === dirty
      }).length > 0
    )
  } else {
    return getDirty().length > 0
  }
}

export const getDirty = () => {
  if (!isBrowser()) {
    return []
  }

  const dirtyLocalStorage = localStorage.getItem(_getLocalStorageIdDirty())

  if (typeof dirtyLocalStorage == 'string') {
    return JSON.parse(dirtyLocalStorage)
  } else {
    return []
  }
}
