import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { getProductsTemplateUrl } from '../../utils/utils'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import { PageInformation } from '../../types'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > #searchquery': {
        width: 250,
      },
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
)

export interface SearchFieldProps {
  pageInformation?: PageInformation
  showSubmitButton: boolean
}

export default function SearchField(props: SearchFieldProps) {
  const { pageInformation } = props
  const { t } = useTranslation('translation')
  const classes = useStyles()

  const [searchQuery, setSearchQuery] = useState('')
  const [material, setMaterial] = useState('poster')
  const [urlFilters, setUrlFilters] = useState<string[]>([])
  const [variant, setVariant] = useState<string>('')

  useEffect(() => {
    if (pageInformation) {
      setSearchQuery(pageInformation.searchQuery || '')
      setUrlFilters(pageInformation.urlFilters || [])
      setVariant(pageInformation.variant || '')

      if (
        pageInformation.type == 'pes' ||
        pageInformation.type == 'hh' ||
        pageInformation.type == 'pues'
      ) {
        setMaterial(pageInformation.material || 'poster')
      }
    }
  }, [pageInformation])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = '' + event.target.value

    setSearchQuery(query)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()

    const query = '' + event.currentTarget.searchquery.value
    console.log('query', query)

    const url = getProductsTemplateUrl(
      material,
      urlFilters,
      'relevance',
      query.length >= 2 ? query : '',
      1,
      variant,
    )

    if (query.length >= 2) {
      trackEvent({
        category: 'OnSiteSearch',
        action: 'Autocomplete',
        label: query,
      })
    }

    if (props.showSubmitButton) {
      navigate(url)
    }
  }

  const stopPropagationEvent = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    /* Prevent closing dropdown when clicking into form */
    event.stopPropagation()
  }


  return (
    <>
      <form
        autoComplete="off"
        className={classes.root}
        noValidate
        onSubmit={handleSubmit}
      >
        <TextField
          autoFocus={true}
          data-artboxone="searchfield"
          id="searchquery"
          label={t('Suche')}
          onChange={handleChange}
          onClick={stopPropagationEvent}
          onMouseDown={stopPropagationEvent}
          onMouseUp={stopPropagationEvent}
          value={searchQuery}
        />

        {props.showSubmitButton && (
          <Button color="primary" type="submit" variant="contained">
            &raquo;
          </Button>
        )}
      </form>
    </>
  )
}
