import React from 'react'
import { Link } from 'gatsby'
import { useHeaderBarData } from '../../lib/global/headerbar_hook'
import { HeaderBarData } from '../../types/api/header_bar'

const HeaderBar = () => {
  const headerBarData: HeaderBarData | undefined = useHeaderBarData()

  if (headerBarData === undefined) {
    return <></>
  } else {
    return (
      <>
        <div className="topbar topbar-sticky">
          <div className="container">
            <div className="row">
              {headerBarData.desktop_text != '-' &&
                headerBarData.desktop_text != '' && (
                  <div className="col d-none d-md-flex justify-content-start truncate-text">
                    <Link to={headerBarData.desktop_link}>
                      {headerBarData.desktop_text}
                    </Link>
                  </div>
                )}
              <div className="col d-flex justify-content-center truncate-text">
                <Link className="sale" to={headerBarData.link}>
                  {headerBarData.text}
                </Link>
              </div>
              {headerBarData.desktop_text2 != '-' &&
                headerBarData.desktop_text2 != '' && (
                  <div className="col d-none d-lg-flex justify-content-end truncate-text">
                    <Link to={headerBarData.desktop_link2}>
                      {headerBarData.desktop_text2}
                    </Link>
                  </div>
                )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default HeaderBar
