import React from 'react'
import { Session } from '../../types'
import {
  getSession,
  setReferrerIfNull,
  setArefIfNull,
  setChannel,
} from '../../lib/aref'
import queryString from 'query-string'
import { trackNonInteractionEvent } from '../../utils/ArtboxoneTracking'
import { getRandomInt } from '../../utils/utils'
import { setVoucherCode } from '../../lib/cart_hook'

export interface TrackingOptions {
  location: Location
}

interface QueryParams {
  aref?: string
  utm_source?: string
  voucher?: string
  source?: string
  utm_medium?: string
  medium?: string
  utm_campaign?: string
  gclid?: string
  fbclid?: string
}

const Tracking = (options: TrackingOptions) => {
  const referrer = typeof document !== 'undefined' ? document.referrer : null

  const params: QueryParams = options.location.search
    ? queryString.parse(options.location.search)
    : {}

  if (referrer !== null) {
    setReferrerIfNull(referrer)
  }

  if (typeof params.aref !== 'undefined') {
    setArefIfNull(params.aref)
  }

  const session: Session = getSession()
  let changed = false

  if (typeof session.abtest === 'undefined' || session.abtest === null) {
    session.abtest = getRandomInt(2) == 0 ? 'control' : 'variant'
    changed = true
  }

  if (typeof params.utm_source !== 'undefined') {
    session.source = params.utm_source
    changed = true
  }

  if (params.voucher) {
    setVoucherCode(params.voucher)
  }

  if (typeof params.source !== 'undefined') {
    session.source = params.source
    changed = true
  }

  if (typeof params.utm_medium !== 'undefined') {
    session.medium = params.utm_medium
    changed = true
  }

  if (typeof params.medium !== 'undefined') {
    session.medium = params.medium
    changed = true
  }

  if (typeof params.utm_campaign !== 'undefined') {
    session.campaign = params.utm_campaign
    changed = true
  }

  if (typeof params.gclid !== 'undefined') {
    session.clid = params.gclid
    session.cltype = 'google_cpc'
    changed = true
  }

  if (typeof params.fbclid !== 'undefined') {
    session.clid = params.fbclid
    session.cltype = 'facebook'
    changed = true
  }

  // if (
  //   (session.session === null || typeof session.session === 'undefined') &&
  //   typeof sessionData !== 'undefined'
  // ) {
  //   session.session = sessionData['session']
  //   changed = true
  // }

  if (changed) {
    setChannel(session)
  }

  if (session.abtest) {
    trackNonInteractionEvent({
      category: 'environment',
      action: 'abtest',
      label: session.abtest,
    })
  }

  // if (session.session) {
  //   setEnvironmentVariable('session', session.session)
  // }

  // if (isBrowser()) {
  //   let usercentrics = localStorage.getItem('ucSettings')
  //   if (usercentrics !== null) {
  //     let uc_accept_all = usercentrics.indexOf('onAcceptAllBtnClick') >= 0
  //     let uc_accept_some = usercentrics.indexOf('onSaveBtnClick') >= 0

  //     if (uc_accept_all) {
  //       trackNonInteractionEvent({
  //         category: 'usercentrics',
  //         action: 'button',
  //         label: 'all',
  //       })
  //     } else if (uc_accept_some) {
  //       trackNonInteractionEvent({
  //         category: 'usercentrics',
  //         action: 'button',
  //         label: 'config',
  //       })
  //     }
  //   }
  // }

  return <></>
}

export default Tracking
