import React from 'react'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import fetchError from '../../lib/global/error_api'

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

interface ErrorBoundaryProps {
  children: React.ReactNode
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error }
  }

  componentDidCatch(error: ReferenceError, errorInfos: React.ErrorInfo) {
    if (process.env.SHOW_CMS_LINKS !== '1') {
      let message = ''

      if (typeof error.message !== 'undefined') {
        if (typeof error.name !== 'undefined') {
          message += '[' + error.name + '] '
        }

        message += error.message
        message += ': ' + error.stack
      } else if (typeof errorInfos !== 'undefined') {
        message = errorInfos.componentStack
      }

      message = message.replace(/(?:\r\n|\r|\n)/g, ', ')

      trackEvent({
        category: 'error',
        action: 'error_boundary',
        label: message,
      })

      fetchError({
        data: message,
      })
    }
  }

  render() {
    if (this.state.hasError) {
      if (process.env.SHOW_CMS_LINKS == '1') {
        return (
          <>
            <h1>Fehler</h1>
            <h2>
              {this.state.error && this.state.error.name
                ? this.state.error.name
                : '...'}
            </h2>
            <p>
              {this.state.error && this.state.error.message
                ? this.state.error.message
                : '...'}
            </p>
            <pre>
              {this.state.error && this.state.error.stack
                ? this.state.error.stack
                : '...'}
            </pre>
          </>
        )
      } else {
        return (
          <>
            <h1>Ups.</h1>
            <p>
              Da ist was kaputt gegangen. Bitte wende dich an{' '}
              <a href="mailto:it@artboxone.de">it@artboxone.de</a>, am besten
              mit der Seite, die du gerade aufrufen wolltest.
            </p>
          </>
        )
      }
    }
    
    if (this.props.children) {
      return <>{this.props.children}</>
    }

    return null
  }
}
