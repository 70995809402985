import React from 'react'
import { getCart } from '../lib/cart_hook'
import { getFavoritesCount } from '../lib/favorites_hook'
import { getCartItemsCount } from '../lib/cartitems_hook'

const searchState = {
  favoritesCount: 0,
  cartCount: 0,
  voucherCode: '',
  setFavoritesCount: (n: number) => {},
  setCartCount: (n: number) => {},
  setVoucherCode: (s: string) => {},
}

const SearchContext = React.createContext(searchState)

class SearchProvider extends React.Component {
  state = {
    favoritesCount: 0,
    cartCount: 0,
    voucherCode: '',
  }

  setVoucherCode = (s: string) => {
    this.setState({ voucherCode: s })
  }

  setFavoritesCount = (n: number) => {
    this.setState({ favoritesCount: n })
  }
  setCartCount = (n: number) => {
    this.setState({ cartCount: n })
  }

  componentDidMount() {
    const cart = getCart()

    this.setState({
      favoritesCount: getFavoritesCount(),
      cartCount: getCartItemsCount(),
      voucherCode: cart.voucher_code,
    })
  }

  render() {
    const { children } = this.props
    const { favoritesCount, cartCount, voucherCode } = this.state
    return (
      <SearchContext.Provider
        value={{
          favoritesCount,
          cartCount,
          voucherCode,
          setFavoritesCount: this.setFavoritesCount,
          setCartCount: this.setCartCount,
          setVoucherCode: this.setVoucherCode,
        }}
      >
        {children}
      </SearchContext.Provider>
    )
  }
}

export default SearchContext
export { SearchProvider }
