import { isBrowser } from '../utils/utils'
import { addDirty } from './dirty_hook'
import { localStorage_setItem } from '../utils/LocalStorageHelper'

export interface Order {
  orderId: number
  hash: string
  mail: string
  date: Date
  paid: boolean
  nps: boolean
  info: string
  data: any
}

export const _getLocalStorageIdOrders = () => {
  return process.env.STORAGE_PREFIX + '_orders'
}
export const _setOrders = (orders: Order[]) => {
  addDirty('orders')
  localStorage_setItem(_getLocalStorageIdOrders(), JSON.stringify(orders))
}

export const getOpenOrderCount = (): number => {
  return getOrders().filter((order: Order) => {
    return !order.nps || !order.paid
  }).length
}

export const addOrder = (
  orderId: number,
  hash: string,
  mail: string,
  data: any,
) => {
  const orders: Order[] = getOrders()

  orders.push({
    orderId: orderId,
    hash: hash,
    mail: mail,
    date: new Date(),
    paid: false,
    nps: false,
    info: '',
    data: data,
  })

  _setOrders(orders)
}

export const setOrderPaid = (orderHash: string) => {
  const orders = getOrders()

  const order = orders.filter((order: Order) => {
    return order.hash === orderHash
  })

  const newOrders = orders.filter((order: Order) => {
    return order.hash !== orderHash
  })

  if (order.length == 1) {
    order[0].paid = true
    newOrders.push(order[0])
    _setOrders(newOrders)
    return true
  } else {
    return false
  }
}

export const setOrderInfo = (orderHash: string, info: string) => {
  const orders = getOrders()

  const order = orders.filter((order: Order) => {
    return order.hash === orderHash
  })

  const newOrders = orders.filter((order: Order) => {
    return order.hash !== orderHash
  })

  if (order.length == 1) {
    order[0].info = info
    newOrders.push(order[0])
    _setOrders(newOrders)
    return true
  } else {
    return false
  }
}

export const setOrderNps = (orderId: number) => {
  const orders = getOrders()

  const order = orders.filter((order: Order) => {
    return order.orderId === orderId
  })

  const newOrders = orders.filter((order: Order) => {
    return order.orderId !== orderId
  })

  if (order.length == 1) {
    order[0].nps = true
    newOrders.push(order[0])
  }

  _setOrders(newOrders)
}

export const addOrderObject = (order: Order) => {
  const orders = getOrders()

  orders.push(order)

  _setOrders(orders)
}

export const isInOrders = (orderId: number) => {
  return (
    getOrders().filter((order: Order) => {
      return order.orderId === orderId
    }).length > 0
  )
}

export const getOrders = (): Order[] => {
  if (!isBrowser()) {
    return []
  }

  const cartLocalStorage = localStorage.getItem(_getLocalStorageIdOrders())

  if (typeof cartLocalStorage == 'string') {
    const result = JSON.parse(cartLocalStorage)

    return result
  } else {
    return []
  }
}
