import { fetchHeaderBar } from './headerbar_api'
import { useState, useEffect } from 'react'
import {env} from '../../../environment'
import { daysIntoYear } from '../../utils/utils'
import { localStorage_setItem } from '../../utils/LocalStorageHelper'
import { HeaderBarData } from '../../types/api/header_bar'

export const useHeaderBarData = (): HeaderBarData | undefined => {
  const [headerBar, setHeaderBar] = useState<HeaderBarData | undefined>(undefined)

  const localeStorageId: string =
    process.env.STORAGE_PREFIX +
    '_headerBar_' +
    env.getCmsLanguage() +
    '_day' +
    daysIntoYear()

  useEffect(() => {
    const artistsFromLocalStorage = localStorage.getItem(localeStorageId)

    if (typeof artistsFromLocalStorage == 'string') {
      setHeaderBar(JSON.parse(artistsFromLocalStorage))
    } else {
      fetchHeaderBar().then(response => {
        setHeaderBar(response.data)
        localStorage_setItem(localeStorageId, JSON.stringify(response.data))
      })
    }
  }, [localeStorageId])

  return headerBar
}
