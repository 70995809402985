import { isBrowser, getRandomInt } from '../utils/utils'
import { Session } from '../types'
import { localStorage_setItem } from '../utils/LocalStorageHelper'

export const _getLocalStorageIdSession = () => {
  return process.env.STORAGE_PREFIX + '_session'
}
export const _setSession = (session: Session) => {
  localStorage_setItem(_getLocalStorageIdSession(), JSON.stringify(session))
}

export const setChannel = (sessionParam: Session) => {
  _setSession(sessionParam)
}

export const blockedReferrer = [
  'artboxone-de',
  'artboxone-at',
  'artboxone-ch',
  'artboxone-dk',
  'artboxone-co-uk',
  'de-artbox-one',
  'dev-artbox-one',
  'at-artbox-one',
  'ch-artbox-one',
  'dk-artbox-one',
  'uk-artbox-one',
  'localhost',
  'paypal-com',
]

export const getLocation = (href: string) => {
  const l = document.createElement('a')
  l.href = href
  return l
}

export const getSessionSession = () => {
  const session = getSession()

  if (typeof session.session !== 'undefined') {
    return session.session
  } else {
    return 'unknown'
  }
}

export const getChannel = () => {
  // HGR: Parameter "aref" soll alle anderen Regeln overrulen und direkt als channel genutzt werden
  // landet dann unver�ndert im pref der pixum bestellung
  // Ansonsten:
  // "g"clid -> gogole_cpc
  // Aber wenn utm-Parameters, dann die
  // Ansonsten referrer

  let result = 'appbox_one'
  const session = getSession()

  if (session.aref !== null) {
    // aref geht vor allen anderen regeln vor
    result = session.aref
  }
  else {
    // kein aref, Baue Channel selber aus Click IDs, URM Parameter oder Referer
    if (
      session.clid !== null &&
      session.cltype !== null &&
      typeof session.cltype !== 'undefined' &&
      ['google_cpc', 'facebook'].indexOf(session.cltype) !== -1
    ) {
      // Falls clid existiert, nutze diese
      result = `${session.cltype}_${session.clid}`
    } else {
      // Ansonsten F�ge source_medium_campaign hintereinander, jeweils nur wenn vorhanden
      if (session.source !== null) {
        result = session.source

        if (session.medium !== null) {
          result += `_${session.medium}`

          if (session.campaign !== null) {
            result += `_${session.campaign}`
          }
        }
      } else {
        // Falls source nicht vorhanden, nutze referer als channel
        if (session.referrer !== null) {
          result = session.referrer
        }
      }
    }
  }

  return result
}

export const setArefIfNull = (aref: string) => {
  const session = getSession()

  if (session.aref === null) {
    session.aref = aref
  }

  _setSession(session)
}

export const setReferrerIfNull = (referrer: string) => {
  const session = getSession()

  if (session.referrer === null && referrer !== '') {
    const l = getLocation(referrer)

    if (l.hostname !== '') {
      const setReferrer = l.hostname.replace('www.', '').replace(/\./g, '-')

      if (blockedReferrer.indexOf(setReferrer) === -1) {
        session.referrer = setReferrer
      } else {
        // console.warn('Blocked referrer: *' + setReferrer + '*')
      }
    }
  }

  _setSession(session)
}

export const getSession = () => {
  if (!isBrowser()) {
    return []
  }

  const sessionLocalStorage = localStorage.getItem(_getLocalStorageIdSession())

  if (typeof sessionLocalStorage == 'string') {
    const result = JSON.parse(sessionLocalStorage)

    return result
  } else {
    const result: Session = {
      aref: null,
      source: null,
      medium: null,
      campaign: null,
      referrer: null,
      clid: null,
      cltype: null,
      session: null,
      abtest: getRandomInt(2) == 0 ? 'control' : 'variant',
    }

    _setSession(result)

    return result
  }
}
