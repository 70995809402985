/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import CssBaseline from '@pixum/cssbaseline'
import withLocation from '../generic/withLocation'
import Header from './Header'
import Footer from './Footer'
import { getNaviLanguage } from '../../utils/CountrySelection'
import { env } from '../../../environment'
import SEO from '../generic/Seo'
import EnvironmentVariables from './EnvironmentVariables'
import { ErrorBoundary } from '../generic/ErrorBoundary'

interface LayoutProps {
  children?: React.ReactNode
  location: Location
}

const Layout: React.FC<LayoutProps> = props => {
  const children = props.children
  const location = props.location

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          language
        }
      }

      allCockpitNavigation {
        nodes {
          id
          name {
            value
          }
          link {
            value
          }
          published {
            value
          }
          lang
          titel_1 {
            value
          }
          titel_2 {
            value
          }
          titel_3 {
            value
          }
          titel_4 {
            value
          }
          felder_1 {
            value {
              id
              name {
                value
              }
              target {
                value
              }
              lang
              grafik {
                value {
                  publicURL
                }
              }
              desktop {
                value
              }
              mobile {
                value
              }
            }
          }
          felder_2 {
            value {
              id
              name {
                value
              }
              target {
                value
              }
              lang
              grafik {
                value {
                  publicURL
                }
              }
              desktop {
                value
              }
              mobile {
                value
              }
            }
          }
          felder_3 {
            value {
              id
              name {
                value
              }
              target {
                value
              }
              lang
              grafik {
                value {
                  publicURL
                }
              }
              desktop {
                value
              }
              mobile {
                value
              }
            }
          }
          felder_4 {
            value {
              id
              name {
                value
              }
              target {
                value
              }
              lang
              grafik {
                value {
                  publicURL
                }
              }
              desktop {
                value
              }
              mobile {
                value
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        description="Der neue Look für deine Wände! Entdecke jetzt tolle Geschenke für deine Liebsten. Lass' dich inspirieren!"
        lang={env.getCmsLanguage()}
        title="artboxONE"
      />
      <CssBaseline />
      <Header
        language={getNaviLanguage()}
        location={location}
        menuLinks={data.allCockpitNavigation.nodes}
        siteTitle={data.site.siteMetadata.title}
      />
      <div id="layout">
        <main>
          <ErrorBoundary>{children}</ErrorBoundary>
        </main>
      </div>

      <EnvironmentVariables />
      <Footer />
    </>
  )
}

export default withLocation(Layout)
