import {
  VoucherData,
  ProductMaterial,
  CartItem,
  CartItemCalculated,
} from './../types'
import { getCartCountry } from './CountrySelection'
import { calculateCartItem, isBroken } from './CartItemUtils'

export const getAmountForBonusCalculation = (
  price: number,
  price_shipping: number,
  price_without_uvp_promotions: number,
  type: string,
  without_shipping: boolean,
) => {
  let priceForVoucherCalculation = price

  if (type === 'percent' && price_without_uvp_promotions >= 0) {
    priceForVoucherCalculation = price_without_uvp_promotions
  }

  if (!without_shipping) {
    priceForVoucherCalculation += price_shipping
  }

  return priceForVoucherCalculation
}

export const getBonus = (
  price: number,
  price_shipping: number,
  voucherData: VoucherData,
  price_without_uvp_promotions: number,
) => {
  if (typeof voucherData.error !== 'undefined') {
    return 0
  }

  const priceForVoucherCalculation = getAmountForBonusCalculation(
    price,
    price_shipping,
    price_without_uvp_promotions,
    voucherData.type,
    voucherData.without_shipping == 1,
  )

  if (voucherData.type == 'percent') {
    return (priceForVoucherCalculation * voucherData.value) / 100
  } else if (voucherData.type == 'absolut') {
    if (priceForVoucherCalculation < voucherData.value) {
      return priceForVoucherCalculation
    } else {
      return voucherData.value
    }
  } else {
    return 0
  }
}

export const getFreeShipping = (country: string) => {
  switch (country) {
    case 'ch':
      return 70
    case 'dk':
      return 500
    default:
    case 'de':
      return 59
  }
}

export const calculateShipping = (
  sumMaterialGross: number,
  country: string,
): number => {
  let sumShipping = 0

  if (country == 'ch') {
    sumShipping = 7
  } else if (country == 'dk') {
    sumShipping = 50
  } else {
    // gb
    // de
    // at
    // roe
    sumShipping = 4.99
  }

  const freeShipping = getFreeShipping(country)

  if (sumMaterialGross >= freeShipping) {
    sumShipping = 0
  }

  return sumShipping
}

export interface CalculateCartResult {
  sumShipping: number;
  sumMaterialGross: number;
  cartItemsToDisplay: CartItemCalculated[];
  cartItemsToRemove: CartItemCalculated[];
  sumUvpBonus: number;
  sumMaterialGrossWithoutUvpPromotions: number;
}

export const calculateCart = (
  cartItems: CartItem[],
  materialsData: ProductMaterial[],
  products: any,
): CalculateCartResult => {
  let sumMaterialGross = 0
  let sumUvpBonus = 0
  let sumMaterialGrossWithoutUvpPromotions = 0

  const cartItemsToDisplay: CartItemCalculated[] = []
  const cartItemsToRemove: CartItemCalculated[] = []

  let onlyVirtual = true

  cartItems.map((cartItem: CartItem) => {
    const cartItemToDisplay = calculateCartItem(
      cartItem,
      materialsData,
      products,
    )

    if (
      ![667, 668, 669, 670].includes(cartItemToDisplay.productMaterial?.iid)
    ) {
      onlyVirtual = false
    }

    if (isBroken(cartItemToDisplay)) {
      cartItemsToRemove.push(cartItemToDisplay)
    } else {
      const { price, priceUvp, count } = cartItemToDisplay

      sumMaterialGross += count * price

      if (priceUvp != price) {
        sumUvpBonus += count * (priceUvp - price)
      } else {
        sumMaterialGrossWithoutUvpPromotions += count * price
      }

      cartItemsToDisplay.push(cartItemToDisplay)
    }
  })

  const sumShipping = calculateShipping(sumMaterialGross, getCartCountry())

  return {
    sumShipping: onlyVirtual ? 0 : sumShipping,
    sumMaterialGross: sumMaterialGross,
    cartItemsToDisplay: cartItemsToDisplay,
    cartItemsToRemove: cartItemsToRemove,
    sumUvpBonus: sumUvpBonus,
    sumMaterialGrossWithoutUvpPromotions: sumMaterialGrossWithoutUvpPromotions,
  }
}
