import React, { ReactNode, createContext, useCallback, useState } from 'react';
import { PageInformation } from '../types';

interface PageInformationContextType {
    pageInformation: PageInformation | undefined;
    resetCounter: number;
    setPageInformation: React.Dispatch<React.SetStateAction<PageInformation | undefined>>;
    resetPageInformation: () => void;
}
const defaultState: PageInformationContextType = {
    pageInformation: undefined ,
    resetCounter: 0,
    setPageInformation: () => {},
    resetPageInformation: () => {},
}
const PageInformationContext = createContext(defaultState);

interface PageInformationProviderProps {
    children: ReactNode;
}

export const PageInformationProvider: React.FC<PageInformationProviderProps> = (props) => {
    const { children } = props;

    const [pageInformation, setPageInformation] = useState<PageInformation | undefined>(undefined);
    const [resetCounter, setResetCounter] = useState(0);

    const resetPageInformation = useCallback(() => {
        setPageInformation(undefined);
        setResetCounter(prev => prev + 1);
      }, []);

    return (
        <PageInformationContext.Provider value={{ 
            pageInformation, 
            resetCounter,
            setPageInformation,
            resetPageInformation
        }}>
            {children}
        </PageInformationContext.Provider>
    )
}

export default PageInformationContext
