import React from 'react'
import { useVersionData } from '../../lib/global/version_hook'
import {
  clearLocalStorageVersion,
  clearLocalStorageExtVersion,
  getExtVersion,
  setExtVersion,
} from '../../utils/Storage'

const Caching = (options: any) => {
  const extVersion = getExtVersion()
  const versionData = useVersionData({})

  if (versionData !== undefined) {
    // console.log('Got version....')
    if (
      versionData['version'] !== 'fallback' &&
      extVersion['version'] != versionData['version']
    ) {
      // console.log('New version....')
      clearLocalStorageExtVersion()
      setExtVersion(versionData['version'])
    } else {
      // console.log('Same version....')
    }

    clearLocalStorageVersion()
  }

  return <></>
}

export default Caching
