import {
  CartItem,
  CartItemCalculated,
  ProductImage,
  ProductMaterial,
} from './../types'
import { getPriceCountry, getCartCountry } from './CountrySelection'
import { buildPesUrl, getThumbnailReplacementType } from './UrlUtils'
import { getProductMaterialFromIid } from './MaterialUtils'
import { getMotiveFromCartItem } from './MotiveUtils'
import { renderImageUrl } from '../lib/imageurl_api'
import { getHash } from './Crypto'

interface CartItemOptions {
  motiveId?: number
  material?: string
  materialIId?: number
  count?: number
  inserted?: number
  ratio?: number
}

export const getCartItem = ({
  motiveId,
  material,
  materialIId,
  count = 1,
  inserted = 0,
  ratio,
}: CartItemOptions) => {
  if (motiveId && material && materialIId) {
    return {
      motiveId,
      material,
      materialIId,
      count,
      inserted,
      ratio,
    }
  }

  return undefined
}

export const calculateCartItem = (
  cartItem: CartItem,
  materialsData: ProductMaterial[],
  products: any,
) => {
  const priceCountry = getPriceCountry(getCartCountry())

  const signature = getHash(cartItem)

  const productMotive = products && getMotiveFromCartItem(cartItem, products)
  const productMaterial = getProductMaterialFromIid(
    materialsData,
    cartItem.materialIId,
  )

  const productLink = buildPesUrl(
    cartItem.material,
    productMaterial?.url_slug || '',
    productMotive?.id,
    productMotive?.urlslug,
    productMotive?.ratio,
    cartItem.customText,
  )

  const productPrice = productMaterial?.price[priceCountry]
  const productPriceUvp = productMaterial?.priceUvp
    ? productMaterial?.priceUvp[priceCountry]
    : productPrice

  const thumbnail: ProductImage = {
    template: `${productMaterial?.images.small}`,
    replacement: getThumbnailReplacementType(`${productMaterial?.material}`),
  }

  const calculatedCartItem = {
    motiveId: cartItem.motiveId,
    material: cartItem.material,
    materialId: cartItem.materialId,
    materialIId: cartItem.materialIId,
    count: cartItem.count,
    signature: signature,
    price: 0 + productPrice || 0,
    priceUvp: 0 + productPriceUvp || 0,
    productMaterial,
    productMotive,
    inserted: cartItem.inserted,
    productLink,
    ratio: cartItem.ratio,
    previewUrl: productMotive
      ? renderImageUrl({
          image: thumbnail,
          productData: productMotive,
        })
      : '',
    customText: cartItem.customText,
  }

  return calculatedCartItem
}

export const isBroken = (cartItem: CartItemCalculated) => {
  const { productMaterial, productMotive, price } = cartItem

  if (
    price <= 0.02 ||
    !productMotive ||
    !productMaterial ||
    productMaterial?.out_of_stock
  ) {
    return true
  }

  return false
}
