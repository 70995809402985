import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import PinterestIcon from '@mui/icons-material/Pinterest'
import { useTranslation } from 'react-i18next'
import CmsBlock from '../cms/CmsBlock'
import ForestIcon from '@mui/icons-material/Forest'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import PhoneIcon from '@mui/icons-material/Phone'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import InfoIcon from '@mui/icons-material/Info'
import BusinessIcon from '@mui/icons-material/Business'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import BrushIcon from '@mui/icons-material/Brush'
import PeopleIcon from '@mui/icons-material/People'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import {env} from '../../../environment'

interface LinkItem {
  href: string
  title: React.ReactNode
}

const Footer = () => {
  const { t } = useTranslation('translation')

  let links: Record<string, LinkItem[]> = {}

  if (env.getCmsLanguage() == 'en') {
    links = {
      artboxONE: [
        {
          href: '/privacysettings',
          title: 'Privacy Settings',
        },
        {
          href: '/contacts',
          title: 'Contacts',
        },
        {
          href: '/infos/shipping',
          title: 'Shipping',
        },
        {
          href: '/infos/materials',
          title: 'Materials',
        },
        {
          href: '/infos/for-artists',
          title: 'For artists',
        },
        {
          href: '/infos/sitemap',
          title: 'Sitemap',
        },
      ],
      Inspiration: [
        {
          href: '/wall-art/poster/floral/bestseller',
          title: 'Floral',
        },
        {
          href: '/wall-art/poster/cities/bestseller',
          title: 'Cities',
        },
        {
          href: '/wall-art/poster/animals/bestseller',
          title: 'Animals',
        },
      ],
      'Folge uns': [
        {
          href: 'https://www.facebook.com/artboxONE',
          title: (
            <>
              <FacebookIcon /> Facebook
            </>
          ),
        },
        {
          href: 'https://www.instagram.com/artboxone/',
          title: (
            <>
              <InstagramIcon /> Instagram
            </>
          ),
        },
        {
          href: 'https://de.pinterest.com/artboxONE/',
          title: (
            <>
              <PinterestIcon /> Pinterest
            </>
          ),
        },
        {
          href: '/newsletter/',
          title: (
            <>
              <AlternateEmailIcon />
              Newsletter
            </>
          ),
        },
      ],
      Footer: [
        {
          href: '/infos/returns/',
          title: 'Returns & Right of withdrawal',
        },
        {
          href: '/infos/privacy/',
          title: 'Privacy',
        },
        {
          href: '/infos/terms-conditions/',
          title: 'Terms & Conditions',
        },
        {
          href: 'https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=EN',
          title: 'Online Dispute Resolution',
        },
        {
          href: '/infos/legal',
          title: 'Legal',
        },
      ],
    }
  } else {
    links = {
      Inspiration: [
        {
          href: '/inspiration',
          title: 'Inspiration für dein Zuhause',
        },
        {
          href: '/wandbilder',
          title: 'Alle Wandbildmaterialien',
        },
        {
          href: '/geschenkideen',
          title: 'Alle Geschenkideen',
        },
        {
          href: '/wandbilder/poster/typografie/bestseller',
          title: 'Typografie',
        },
        {
          href: '/wandbilder/poster/floral/bestseller',
          title: 'Floral',
        },
        {
          href: '/wandbilder/poster/staedte/bestseller',
          title: 'Städte',
        },
        {
          href: '/wandbilder/poster/tiere/bestseller',
          title: 'Tiere',
        },
      ],
      artboxONE: [
        {
          href: '/datenschutzeinstellungen',
          title: (
            <>
              <VerifiedUserIcon />
              Datenschutzeinstellungen
            </>
          ),
        },

        {
          href: '/informationen/Nachhaltigkeit',
          title: (
            <>
              <ForestIcon /> Wir und unsere Umwelt
            </>
          ),
        },
        {
          href: '/informationen/faq-haeufige-fragen',
          title: (
            <>
              <LiveHelpIcon />
              FAQs
            </>
          ),
        },
        {
          href: '/informationen/jobs',
          title: (
            <>
              <EmojiPeopleIcon /> Jobs
            </>
          ),
        },
        {
          href: '/contacts',
          title: (
            <>
              <PhoneIcon />
              Kontakt
            </>
          ),
        },
        {
          href: '/informationen/lieferung',
          title: (
            <>
              <LocalShippingIcon />
              Lieferung
            </>
          ),
        },
        {
          href: '/informationen/materialien-uebersicht',
          title: (
            <>
              <InfoIcon />
              Materialien
            </>
          ),
        },
        {
          href: '/informationen/fuer-kuenstler',
          title: (
            <>
              <BrushIcon />
              Für Künstler
            </>
          ),
        },
        {
          href: '/inspiration/grosskunden',
          title: (
            <>
              <BusinessIcon />
              Für Großkunden
            </>
          ),
        },
        {
          href: '/informationen/sitemap',
          title: (
            <>
              <AccountTreeIcon />
              Sitemap
            </>
          ),
        },
        {
          href: '/informationen/kuenstler',
          title: (
            <>
              <PeopleIcon />
              Unsere Künstler
            </>
          ),
        },
      ],
      'Folge uns': [
        {
          href: 'https://www.tiktok.com/@artboxone',
          title: 'TikTok',
        },
        {
          href: 'https://www.instagram.com/artboxone/',
          title: (
            <>
              <InstagramIcon /> Instagram
            </>
          ),
        },
        {
          href: 'https://www.facebook.com/artboxONEde',
          title: (
            <>
              <FacebookIcon /> Facebook
            </>
          ),
        },
        {
          href: 'https://de.pinterest.com/artboxONE/',
          title: (
            <>
              <PinterestIcon /> Pinterest
            </>
          ),
        },
        {
          href: '/nl_anmeldung/',
          title: (
            <>
              <AlternateEmailIcon />
              Newsletter
            </>
          ),
        },
      ],
      Footer: [
        {
          href: '/informationen/widerrufsbelehrung',
          title: 'Widerrufsbelehrung',
        },
        {
          href: '/informationen/datenschutz/',
          title: 'Datenschutz',
        },
        {
          href: '/informationen/agb-kundeninfos/',
          title: 'AGB/Kundeninfos',
        },
        {
          href: 'https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.chooseLanguage',
          title: 'Beschwerde/Schlichtung',
        },
        {
          href: '/informationen/impressum',
          title: 'Impressum',
        },
      ],
    }
  }

  return (
    <footer>
      <div id="TrustedShopsTrustBadge">&nbsp;</div>
      <div className="container">
        <div className="row d-block d-sm-none">
          <div className="col-sm-4 text-center">
            <h3>{t('Wir helfen dir gern!')}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 text-center">
            <CmsBlock blockId="footer_block_1" />
          </div>
          <div className="col-sm-4 text-center">
            <CmsBlock blockId="footer_block_2" />
          </div>
          <div className="col-sm-4 text-center">
            <CmsBlock blockId="footer_block_3" />
          </div>
        </div>
      </div>
      <div className="container-full container trust">
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-center">
              <CmsBlock blockId="footer_block_4" />
            </div>
            <div className="col-md-4 text-center">
              <CmsBlock blockId="footer_block_5" />
            </div>
            <div className="col-md-4 text-center">
              <CmsBlock blockId="footer_block_6" />
            </div>
          </div>
        </div>
      </div>

      <div className="container-full container bottombar">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 text-center-on-mobile">
              <h4>Inspiration</h4>
              <ul>
                {links['Inspiration'].map((link: LinkItem, i) => (
                  <li key={i}>
                    <a href={link.href}>{link.title}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-sm-4 text-center-on-mobile">
              <h4>artboxONE</h4>
              <ul>
                {links['artboxONE'].map((link: LinkItem, i) => (
                  <li key={i}>
                    <a href={link.href}>{link.title}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-sm-4 text-center-on-mobile">
              <h4>{t('Folge uns')}</h4>
              <ul>
                {links['Folge uns'].map((link: LinkItem, i) => (
                  <li key={i}>
                    <a href={link.href}>{link.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container-full container bottombar bottombar-black">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 text-center-on-mobile">
              <ul>
                <li>
                  <a href={env.getPixumDomain()} rel="noreferrer" target="_blank">
                    Pixum
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-sm-4 text-center-on-mobile">
              <ul>
                {links.Footer.map((link: LinkItem, i) => (
                  <li key={i}>
                    <a href={link.href}>{link.title}</a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-sm-4 text-center-on-mobile">
              © {new Date().getFullYear()} artboxONE
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
