import {env} from '../../../environment'

export const fetchHeaderBar = () => {
  const locale = env.getLocale()
  let language = env.getCmsLanguage()

  if (locale == 'de-CH') {
    language = 'ch'
  } else if (locale == 'en-DK') {
    language = 'da'
  }

  const url =
    env.getServiceHost() + 'voucher/currentVoucherInfo?language=' + language

  return fetch(url).then((response) => response.json())
}
