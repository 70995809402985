import React, { useContext, useEffect } from 'react'
import { ClientRootProvider } from './src/components/RootProvider'
import { SSRProvider } from 'react-bootstrap'
import './src/styles/bootstrap.min.css'
import './src/styles/global.css'
import './src/styles/layout.css'
import './src/styles/product_preview.css'
import './src/styles/product_configuration.css'
import './src/styles/header.css'
import './src/styles/footer.css'
import './src/styles/pues.css'
import './src/styles/teaser.css'
import './src/styles/pagination.css'
import './src/styles/cart.css'
import './src/styles/lp.css'
import initI18n from './src/pixum/lib/i18n'
import Backend from 'i18next-fetch-backend'
import { LANGUAGE } from './defaults'
import {
  trackPageView,
  initializeTracking,
} from './src/utils/ArtboxoneTracking'
import PageInformationContext from './src/context/PageInformationContext'
import Layout from './src/components/layout/Layout'

const pathToTranslationLocales = '/locales/{{lng}}/{{ns}}.json'

const backend = new Backend()
backend.init(null, { loadPath: pathToTranslationLocales })

const i18n = initI18n(LANGUAGE, backend)

export const wrapRootElement = ClientRootProvider(i18n)

export const wrapPageElement = ({ element, props }) => {
  // Reset page information on route change
  const { resetPageInformation } = useContext(PageInformationContext)
  useEffect(() => {
    resetPageInformation()
  }, [props.location.pathname])

  return (
    <SSRProvider>
      <Layout {...props}>{element}</Layout>
    </SSRProvider>
  )
}

initializeTracking()

const getLatestSavedScrollPosition = (pathname, key) => {
  let length = sessionStorage.length
  const MAX_LENGTH = 50

  const partialKey = `@@scroll|${pathname}|${key}`

  let results = []

  let i = length

  while (--i >= 0) {
    const key = sessionStorage.key(i)

    if (key.includes(partialKey)) {
      results.push(key)
    }
  }

  if (results.length === 0) {
    return 0
  }

  if (length > MAX_LENGTH) {
    i = length - MAX_LENGTH

    while (--i >= 0) {
      const key = sessionStorage.key(i)
      sessionStorage.removeItem(key)
    }
  }

  results.sort()

  let result = sessionStorage.getItem(results[results.length - 1])

  return result
}

export const onRouteUpdate = ({ location }) => {
  const transitionDelay = 300

  trackPageView(location.pathname)

  const currentPosition = getLatestSavedScrollPosition(
    location.pathname,
    location.key,
  )

  window.scrollTo({
    top: currentPosition,
    behavior: 'instant',
  })

  window.setTimeout(
    () =>
      window.scrollTo({
        top: currentPosition,
        behavior: 'instant',
      }),
    transitionDelay,
  )
}
