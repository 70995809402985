import { ProductImage, ProductData } from '../types'

export interface RenderImageUrlProps {
  image: ProductImage
  productData: ProductData
  customText?: string | undefined
}

export const renderImageUrl = (props: RenderImageUrlProps) => {
  const result = __renderImageUrlInternal(props)

  return result
  // ?.replace(
  //   'productimages.artboxone.com',
  //   'productimages-test.artboxone.com',
  // )
}

export const __renderImageUrlInternal = (props: RenderImageUrlProps) => {
  if (props.image.replacement === 'pixumImageId') {
    let result = props.image.template.replace(
      '%d',
      '' + props.productData.pixumImageId,
    )

    if (props.customText !== undefined && result != '') {
      result += '?text=' + encodeURIComponent(props.customText)
    }

    return result
  } else if (props.image.replacement === 'motiveId') {
    return props.image.template.replace('%d', '' + props.productData.id)
  } else if (props.image.replacement === 'motiveIds') {
    const result = props.image.template.replace('%d', '' + props.productData.id)
    return result.replace('%d', '' + props.productData.id)
  } else {
    return null
  }
}

export interface RenderImageUrlPixumImageIdProps {
  template: string
  pixumImageId: number
}

export const renderImageUrlPixumImageId = (
  props: RenderImageUrlPixumImageIdProps,
) => {
  return props.template.replace('%d', '' + props.pixumImageId)
}
